<style scoped>
</style>

<template>
  <!-- <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange"> -->
  <AppPage>
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="goodsName">货物名称</Option>
                  <Option value="goodsCode">货物编码</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col>类型: </i-col>
            <i-col>
              <Dropdown @on-click="type => onSearch({ type })">
                <Tag size="large" class="noborder click" color="blue" v-if="query.type === '1'">生成<Icon type="ios-arrow-down"></Icon></Tag>
                <Tag size="large" class="noborder click" color="purple" v-else-if="query.type === '2'">消耗<Icon type="ios-arrow-down"></Icon></Tag>
                <Tag size="large" class="noborder click" color="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem :selected="query.status === '1'" name="1">生成</DropdownItem>
                  <DropdownItem :selected="query.status === '2'" name="2">消耗</DropdownItem>
                  <DropdownItem :selected="query.status === 'all'" name="all">全部</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          <Button v-if="$authFunsProxy.manage" style="margin-left: 10px;" @click="addClick" icon="md-add" type="primary">新增</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:type="{ row }">
          <Tag class="noborder" color="blue" v-if="row.type === 1">生成</Tag>
          <Tag class="noborder" color="purple" v-if="row.type === 2">消耗</Tag>
        </template>
        <template v-slot:goodsId="{ row }">
          <Tooltip transfer>
            <Tag class="noborder" :color="row.goodsStatusData.color">
              {{ row.goodsName }}
              {{ row.goodsGgxh }}
            </Tag>
            <template v-slot:content>
              <div>{{ row.goodsCode }}</div>
              <div>
                <span>{{ row.goodsStatusData.label }}</span>
                <span> - </span>
                <span>{{ row.goodsUseTypeText }}</span>
              </div>
            </template>
          </Tooltip>
        </template>
        <template v-slot:goodsProduceStandardId="{ row }">
          <Tooltip transfer :content="row.goodsProduceStandardStatusData.label">
            <Tag class="noborder" :color="row.goodsProduceStandardStatusData.color">{{ row.goodsProduceStandardName }}</Tag>
          </Tooltip>
        </template>
        <template v-slot:volume="{ row }">
          {{ row.volume }}
          {{ row.goodsUnit }}
        </template>
        <template v-slot:lossVolume="{ row }">
          {{ row.lossVolume }}
          {{ row.goodsUnit }}
        </template>
        <template v-slot:action="{ row }">
          <Button @click="itemUpdate(row)" type="text" size="small">修改</Button>
          <Poptip style="margin-left: 10px" transfer title="确定删除吗?" confirm @on-ok="itemDel(row)">
            <Button size="small" :loading="loading.del" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </template>
    <template v-slot:footer v-if="$authFunsProxy.manage">
      <Drawer placement="right" title="维护明细" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <DetailForm type="in" ref="form" :data="formData" />
        </DrawerAction>
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../../components/page/base.vue'
import DrawerAction from '../../../components/drawer/action.vue'
import request from '../../../api/index'
import DetailForm from '../../../components/goods/produce/standard/form/detail.vue'
import { getDefault } from '../../../components/goods/produce/standard/form/detail.vue'

export default {
  components: { AppPage, DetailForm, DrawerAction },
  props: {
    goodsId: { type: Number, default: null },
    goodsProduceStandardId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'goodsName',
        value: null,
        type: 'all'
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false,
        detail: false
      },
      formData: null
    }
  },
  computed: {
    scmGoodsProduceStandardStatusList () {
      return this.$store.getters.scmGoodsProduceStandardStatusList
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    currentGoodsId () {
      let goodsId = this.goodsId || this.$route.query.goodsId
      return goodsId && !isNaN(goodsId) ? Number(goodsId) : null
    },
    currentStandardId () {
      let id = this.goodsProduceStandardId || this.$route.query.goodsProduceStandardId
      return id && !isNaN(id) ? Number(id) : null
    },
    columns () {
      let data = [
        { title: '生产标准', key: 'goodsProduceStandardId', slot: 'goodsProduceStandardId' },
        { title: '类型', key: 'type', slot: 'type' },
        { title: '货物', key: 'goodsId', slot: 'goodsId' },
        { title: '数量', key: 'volume', width: 80, slot: 'volume' },
        { title: '损耗', key: 'lossVolume', width: 80, slot: 'lossVolume' },
        { title: '备注', key: 'remark', ellipsis: true, tooltip: true, width: 160 }
      ]
      if (this.$authFunsProxy.manage) {
        data.push({ title: '操作', key: 'action', slot: 'action' })
      }
      return data
    },
    searchKey () {
      return [this.currentGoodsId, this.currentStandardId].join('-')
    }
  },
  watch: {
    searchKey () {
      if (this.routerName === this.$route.name) {
        this.refresh()
      }
    }
  },
  methods: {
    itemUpdate (item) {
      this.formData = item
      this.status.form = true
    },
    async itemDel (item) {
      this.loading.del = true
      try {
        await request.delete('/goods_produce_standard/detail/' + item.id)
        this.$Message.success('已删除')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.del = false
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (!data) {
          return this.$Message.warning('请完整填写表单')
        }
        this.loading.submit = true
        if (data.id) {
          await request.put('/goods_produce_standard/detail/' + data.id, data)
        } else {
          if (!data.goodsProduceStandardId) {
            data.goodsProduceStandardId = this.currentStandardId
          }
          if (!data.goodsId) {
            data.goodsId = this.currentGoodsId
          }
          await request.post('/goods_produce_standard/detail', data)
        }
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    addClick () {
      let data = getDefault()
      if (this.currentGoodsId) {
        data.goodsId = this.currentGoodsId
      }
      if (this.currentStandardId) {
        data.goodsProduceStandardId = this.currentStandardId
      }
      this.formData = data
      this.status.form = true
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'goodsName'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      // if (this.currentGoodsId) {
      //   params.goodsId = this.currentGoodsId
      // }
      if (this.currentStandardId) {
        params.goodsProduceStandardId = this.currentStandardId
      }
      if (this.query.type !== 'all') {
        params.type = Number(this.query.type)
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_produce_standard/detail', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsStatusData = this.scmGoodsStatusList.find(v => v.value === item.goodsStatus)
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
          item.goodsProduceStandardStatusData = this.scmGoodsProduceStandardStatusList.find(v => v.value === item.goodsProduceStandardStatus)
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    }
  },
  mounted () {
    this.onRowsChange(999)
  }
}
</script>
