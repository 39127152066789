import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=7b7ec7f5&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b7ec7f5",
  null
  
)

export default component.exports